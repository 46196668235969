import {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Modal from 'react-bootstrap/Modal';
import CloseIcon from '@mui/icons-material/Close';
import {connect} from "react-redux";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {cartSave, getAllCart, messageReset} from "../../store/cart";
import {toast, ToastContainer} from "react-toastify";
import {useTelegram} from "../useTelegram";
import {useTranslation} from "react-i18next";

function ProductModal(props) {
    const {tg, userTg, onClose} = useTelegram()
    const {t, i18n} = useTranslation()
    const [show, setShow] = useState(false);
    const [product_attribute, setProductAttribute] = useState([]);
    const [product_attribute_inside, setProductAttributeInside] = useState([]);
    const [quantity, setQuantity] = useState(1);
    const [priceProduct, setPriceProduct] = useState(0);

    useEffect(() => {
        if (props.message == 'deleted') {
            toast.success(t('product_deleted'), {theme: 'colored', draggable: false, autoClose: 1600, delay: 10})
            props.messageReset()
        } else if (props.message === 'error') {
            toast.error(t('error'), {theme: 'colored', draggable: false, autoClose: 1600})
            props.messageReset()
        } else if (props.message === true) {
            toast.success(t('product_added'), {theme: 'colored', draggable: false, autoClose: 1600, delay: 10})
            props.messageReset()
            props.getAllCart(userTg ? userTg.id : '11')
            props.setIsOpen(false)
        } else if (props.message === 'edited') {
            props.messageReset()
        }
    }, [props.message])

    useEffect(() => {
        setQuantity(1)
        if (props.orderItem[`product_attribute`]) {
            let newPrAttr = []
            for (let i = 0; i < props.orderItem[`product_attribute`].length; i++) {
                let newData = {
                    "title": "",
                    "val_uz": "",
                    "val_ru": ""
                }
                if (props.orderItem[`product_attribute`][i][`title_${i18n.language}`]) {
                    newData['title'] = props.orderItem[`product_attribute`][i][`title_${i18n.language}`]
                }
                newPrAttr.push(newData)
            }
            setProductAttribute(newPrAttr)
        }
        setPriceProduct(props.orderItem.price)
    }, [props])

    useEffect(() => {
        let sum = 0
        for (let i = 0; i < product_attribute.length; i++) {
            if (product_attribute[i].add_sum != undefined) {
                sum += product_attribute[i].add_sum
            }
        }
        sum += props.orderItem.price
        setPriceProduct(sum)
    }, [product_attribute])

    function addToCart(item) {
        const date = new Date()
        var utc = date.getTime() + (date.getTimezoneOffset() * 60000);
        var nd = new Date(utc + (3600000 * "+5"));
        if (!(nd.getHours() >= 9 && nd.getHours() < 22)) {
            toast.error(t("time_work"), {theme: 'colored', draggable: false, autoClose: 3000, position: "top-center"})
        } else {
            let data = {};
            data.buyer = userTg ? userTg.id : '11'
            data.quantity = quantity
            data.product = item.id
            let attr = []
            for (let i = 0; i < product_attribute.length; i++) {
                if (product_attribute[i].val_uz){
                    let attr_data = {
                        'title_uz': product_attribute[i].val_uz,
                        'title_ru': product_attribute[i].val_ru,
                        'add_sum': product_attribute[i].add_sum
                    }
                    attr.push(attr_data)
                }
            }
            data.attr = attr
            props.cartSave(data)
            setProductAttribute([])
            setProductAttributeInside([])
            setPriceProduct(0)
        }
    }

    function AddQuantity() {
        setQuantity(quantity + 1)
    }

    function RemoveQuantity() {
        setQuantity(quantity - 1)
    }

    function handleClose() {
        setShow(false);
        props.setIsOpen(false);
        setProductAttribute([])
        setProductAttributeInside([])
        setPriceProduct(0)
    }

    function changeAttr(ind, item_title, item, value_uz, value_ru, add_sum) {
        if (props.orderItem[`product_attribute`]) {
            product_attribute[ind].val_uz = value_uz
            product_attribute[ind].val_ru = value_ru
            product_attribute[ind].add_sum = add_sum ? add_sum : 0
            setProductAttribute([...product_attribute])
            if (item.plus_attributes?.length) {
                for (let i = 0; i < item.plus_attributes.length; i++) {
                    if (i18n.language === 'uz') {
                        if (item.title_uz == value_uz) {
                            let newData = {
                                'title': item.plus_attributes[0].title_uz,
                                'val_uz': '',
                                'val_ru': '',
                            }
                            if (!product_attribute.some(attr => attr.title === item.plus_attributes[0].title_uz)) {
                                setProductAttribute([...product_attribute, newData])
                            } else {
                                let myNewArr = []
                                for (let j = 0; j < product_attribute.length; j++) {
                                    for (let k = 0; k < item.plus_attributes.length; k++) {
                                        if (product_attribute[j].title === item.plus_attributes[k].title_uz) {
                                            for (let s = 0; s < item.plus_attributes[k].product_attribute_plus_attibutes.length; s++) {
                                                if (item.plus_attributes[k].product_attribute_plus_attibutes[s].title_uz == product_attribute[j].val_uz) {
                                                    newData.add_sum = item.plus_attributes[k].product_attribute_plus_attibutes[s].add_sum
                                                    newData.val_uz = item.plus_attributes[k].product_attribute_plus_attibutes[s].title_uz
                                                    newData.val_ru = item.plus_attributes[k].product_attribute_plus_attibutes[s].title_ru
                                                }
                                            }
                                            myNewArr.push(newData)
                                        } else {
                                            myNewArr.push(product_attribute[j])
                                        }
                                    }
                                }
                                setProductAttribute(myNewArr)
                            }
                            if (product_attribute_inside.length) {
                                let ins_arr = [...product_attribute_inside]
                                let is_inside = false
                                for (let d = 0; d < product_attribute_inside.length; d++) {
                                    for (let r = 0; r < item.plus_attributes.length; r++) {
                                        if (product_attribute_inside[d]['title_uz'] === item.plus_attributes[r]['title_uz']) {
                                            is_inside = true
                                            let newObj = Object.assign({}, item.plus_attributes[r], {"parent": item.title_uz});
                                            ins_arr[d] = newObj
                                        } else {
                                            if (is_inside === false && d == product_attribute_inside.length-1){
                                                let newObj = Object.assign({}, item.plus_attributes[r], {"parent": item.title_uz});
                                                ins_arr.push(newObj)
                                            }
                                        }
                                    }
                                }
                                setProductAttributeInside(ins_arr)
                            } else {
                                let newObj = Object.assign({}, item.plus_attributes[0], {"parent": item.title_uz});
                                setProductAttributeInside([newObj])
                            }
                        }
                    } else {
                        if (item.title_ru == value_ru) {
                            let newData = {
                                'title': item.plus_attributes[0].title_ru,
                                'val_uz': '',
                                'val_ru': '',
                            }
                            if (!product_attribute.some(attr => attr.title === item.plus_attributes[0].title_ru)) {
                                setProductAttribute([...product_attribute, newData])
                            } else {
                                let myNewArr = []
                                for (let j = 0; j < product_attribute.length; j++) {
                                    for (let k = 0; k < item.plus_attributes.length; k++) {
                                        if (product_attribute[j].title === item.plus_attributes[k].title_ru) {
                                            for (let s = 0; s < item.plus_attributes[k].product_attribute_plus_attibutes.length; s++) {
                                                if (item.plus_attributes[k].product_attribute_plus_attibutes[s].title_ru == product_attribute[j].val_ru) {
                                                    newData.add_sum = item.plus_attributes[k].product_attribute_plus_attibutes[s].add_sum
                                                    newData.val_uz = item.plus_attributes[k].product_attribute_plus_attibutes[s].title_uz
                                                    newData.val_ru = item.plus_attributes[k].product_attribute_plus_attibutes[s].title_ru
                                                }
                                            }
                                            myNewArr.push(newData)
                                        } else {
                                            myNewArr.push(product_attribute[j])
                                        }
                                    }
                                }
                                setProductAttribute(myNewArr)
                            }
                            let ins_arr = [...product_attribute_inside]
                            if (product_attribute_inside.length) {
                                let is_inside = false
                                for (let d = 0; d < product_attribute_inside.length; d++) {
                                    for (let r = 0; r < item.plus_attributes.length; r++) {
                                        if (product_attribute_inside[d]['title_ru'] === item.plus_attributes[r]['title_ru']) {
                                            let newObj = Object.assign({}, item.plus_attributes[r], {"parent": item.title_ru});
                                            ins_arr[d] = newObj
                                        } else {
                                            if (is_inside === false && d == product_attribute_inside.length-1){
                                                let newObj = Object.assign({}, item.plus_attributes[r], {"parent": item.title_ru});
                                                ins_arr.push(newObj)
                                            }
                                        }
                                    }
                                }
                                setProductAttributeInside(ins_arr)
                            } else {
                                let newObj = Object.assign({}, item.plus_attributes[0], {"parent": item.title_ru});
                                setProductAttributeInside([newObj])
                            }
                        }
                    }
                }
            }
        }
    }

    useEffect(() => {
        setShow(props.isOpen)
    }, [props.isOpen])


    function checkItem(item, index) {
        for (let i = 0; i < item['product_attribute_plus'].length; i++) {
            if (item['product_attribute_plus'][i]['plus_attributes'].length
                && (product_attribute[index]['parent'] === item.title_uz || product_attribute[index]['parent'] === item.title_ru)) {
                return true
            }
        }
        return false
    }


    return (
        <div>
            <ToastContainer/>
            {props.orderItem ?
                <Modal show={show} onHide={handleClose} className={'product-order mt-3'}
                       aria-labelledby="contained-modal-title-vcenter">
                    <div>
                        <img src={props.orderItem.photo} width={100} height={220}
                             alt={props.orderItem[`title_${i18n.language}`]}/>
                        <h3>{props.orderItem[`title_${i18n.language}`]}</h3>
                        {props.orderItem[`description_${i18n.language}`] ?
                            <p>{props.orderItem[`description_${i18n.language}`]}</p> : ''}
                        {props.orderItem[`product_attribute`] ? <div className={'selects'}>
                            {props.orderItem[`product_attribute`].map((item, index) => <div key={index}>
                                <FormControl className={'w-100'}>
                                    <FormLabel id="demo-radio-buttons-group-label"
                                               className={'d-flex justify-content-between'}>
                                        {item[`title_${i18n.language}`]}
                                    </FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        name="radio-buttons-group"
                                        value={i18n.language == 'uz' ? product_attribute?.length ? product_attribute[index]?.val_uz : '' : product_attribute?.length ? product_attribute[index]?.val_ru : ''}
                                    >
                                        {item[`product_attribute_plus`].map((sc_item, sc_index) =>
                                            <FormControlLabel key={sc_index}
                                                              onChange={() => changeAttr(index, item[`title_${i18n.language}`], sc_item, sc_item[`title_uz`], sc_item[`title_ru`], sc_item.add_sum)}
                                                              value={sc_item[`title_${i18n.language}`]}
                                                              control={<Radio/>}
                                                              label={sc_item[`title_${i18n.language}`]}/>
                                        )}
                                    </RadioGroup>
                                </FormControl>
                                {product_attribute_inside.length ? product_attribute_inside.filter(item => item['parent'] == product_attribute[index]['val_uz']).map((itemin, indexin) =>
                                    <FormControl className={'mt-2 w-100'} key={indexin}>
                                        <FormLabel id="demo-radio-buttons-group-label"
                                                   className={'d-flex justify-content-between'}>
                                            {itemin[`title_${i18n.language}`]}
                                        </FormLabel>

                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            name="radio-buttons-group"
                                            value={i18n.language == 'uz' ? product_attribute?.length && product_attribute[props.orderItem[`product_attribute`].length] ? product_attribute[props.orderItem[`product_attribute`].length].val_uz : ''
                                                : product_attribute?.length && product_attribute[props.orderItem[`product_attribute`].length] ? product_attribute[props.orderItem[`product_attribute`].length].val_ru : ''}
                                        >
                                            {itemin[`product_attribute_plus_attibutes`].map((sc_item, sc_index) =>
                                                <FormControlLabel key={sc_index}
                                                                  value={sc_item[`title_${i18n.language}`]}
                                                                  onChange={() => changeAttr(index + (indexin + 2), item[`title_${i18n.language}`], sc_item, sc_item[`title_uz`], sc_item[`title_ru`], sc_item.add_sum)}
                                                                  control={<Radio/>}
                                                                  label={sc_item[`title_${i18n.language}`]}/>
                                            )}
                                        </RadioGroup>
                                    </FormControl>
                                ) : ''}
                            </div>)}
                        </div> : ''}
                    </div>
                    <div className={'addToCartBlock'}>
                        <h4 className={'price-product'}>{quantity * priceProduct} {t('sum')}</h4>
                        <div className={'counter'}>
                            <p>{t('quantity')}</p>
                            <button onClick={RemoveQuantity} disabled={quantity === 1 ? true : false}
                                    className={`quantityBtn`}>-
                            </button>
                            <span className={'quantity'}>{quantity}</span>
                            <button onClick={AddQuantity} className={'quantityBtn'}>+</button>
                        </div>
                        <button className={'addToCartBtn'}
                                onClick={() => addToCart(props.orderItem)}>{t('add_to_cart')}
                        </button>
                    </div>
                    <Button variant="contained" className={'close-btn'}
                            onClick={handleClose}><CloseIcon/></Button>
                </Modal> : ''}

        </div>
    )
}

export default connect(props => props.cart, {cartSave, messageReset, getAllCart})(ProductModal)