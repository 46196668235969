import {useEffect, useState} from "react";
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore from 'swiper';
import {Navigation, Autoplay} from 'swiper/modules';
import IconButton from '@mui/material/IconButton';
import ProductModal from "./blocks/modals/productModal";
import SearchIcon from '@mui/icons-material/Search';
import 'swiper/css';
import 'swiper/css/navigation';
import {connect} from "react-redux";
import {getAllCart, messageReset} from "./store/cart";
import {getFilteredProducts, getProductNextPage, getProducts, getProductTitleFilter} from "./store/Products";
import {getCategories} from "./store/Categories";
import Skeleton from "react-loading-skeleton";
import InfiniteScroll from 'react-infinite-scroll-component';
import CartModal from "./blocks/modals/cartModal";
import {InputAdornment, TextField} from "@mui/material";
import {toast} from "react-toastify";
import {getMyUser} from "./store/User";
import {useTelegram} from "./blocks/useTelegram";
import {useTranslation} from "react-i18next";

SwiperCore.use([Navigation]);
SwiperCore.use([Autoplay]);



function numberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

function App(props) {

    const {tg, userTg, onClose} = useTelegram()
    const {t, i18n} = useTranslation()
    const [user, setUser] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingProduct, setProductLoading] = useState(false);
    const [nextPage, setNextPage] = useState(false);
    const [modal, setModal] = useState(false)
    const [cart, setCart] = useState([])
    const [allSumCart, setAllSumCart] = useState(0)
    const [openItem, setOpenItem] = useState(false)
    const [cartModal, setCartModal] = useState(false)
    const [products, setProducts] = useState([])
    const [categories, setCategories] = useState([])
    const [searchWord, setSearchWord] = useState(false)
    const [categoryId, setCategoryId] = useState(false)

    function changeLang(lang) {
        i18n.changeLanguage(lang)
    }

    function windowTop(val) {
        const searchElement = document.querySelectorAll('.search-box')[0]
        const offsetTopElement = searchElement.offsetTop
        window.scrollTo(0, offsetTopElement - 10)
    }

    function orderProduct(item) {
        setModal(true)
        setOpenItem(item)
    }

    function getCategoryProducts(e, item) {
        if (categoryId == item.id) {
            props.getProducts()
            setCategoryId(false)
        } else {
            props.getFilteredProducts(item.id)
            setCategoryId(item.id)
            windowTop()
        }
        loadingDataProduct()
    }

    function Filtering(event) {
        event.preventDefault()
        setSearchWord(event.target.value)
        props.getProductTitleFilter(event.target.value)
    }

    useEffect(() => {
        tg.ready()
        tg.expand()
        loadingData()
        props.getMyUser(userTg ? userTg.id : '11')
        props.getAllCart(userTg ? userTg.id : '11')
        props.getProducts()
        props.getCategories()
    }, [])
    

    useEffect(() => {
        if (props.user && props.user.user){
            setUser(props.user.user)
            changeLang(props.user.user.language ? props.user.user.language : 'uz')
        }

        const myCart = props.cart.cart
        if (myCart && myCart.length > 0) {
            let sum = 0;
            for (let i = 0; i < myCart.length; i++) {
                sum += myCart[i].product.price * Number(myCart[i].quantity);
                if(myCart[i].attr.length){
                    for (let j = 0; j < myCart[i].attr.length; j++) {
                        sum += myCart[i].attr[j].add_sum * Number(myCart[i].quantity)
                    }
                }
            }
            setAllSumCart(sum)
        } else {
            setAllSumCart(0)
        }
        setCart(myCart)
        if (props.message === true) {}

    }, [props])

    useEffect(() => {
        const myProducts = props.product.products
        setProducts(myProducts['results'])
    }, [props.product.products])


    useEffect(() => {
        const myCategories = props.categories.categories
        setCategories(myCategories)
    }, [props.categories])


    function getNextPage() {
        const page = props.product.products.next
        const newpage = page.split("products/?").pop()
        props.getProductNextPage(newpage)
    }

    function openCart() {
        const date = new Date()
        var utc = date.getTime() + (date.getTimezoneOffset() * 60000);
        var nd = new Date(utc + (3600000 * "+5"));
        if(!(nd.getHours() >= 9 && nd.getHours() < 22)){
            toast.error(t("time_work"), {theme: 'colored', draggable: false, autoClose: 3000, position: "top-center"})
        } else if (cart.length) {
            setCartModal(!cartModal)
        } else {
            toast.info(t('cart_clear'), {
                position: "top-center",
                autoClose: 2000,
                closeOnClick: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    }

    function loadingDataProduct() {
        setProductLoading(true);
        setTimeout(() => {
            setProductLoading(false);
        }, 700);
    }

    function loadingData() {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }

    return (
        <main>
            <ProductModal isOpen={modal} orderItem={openItem} setIsOpen={() => setModal()}/>
            <CartModal allSumCart={allSumCart} isOpen={cartModal} setCartApp={() => setCart()}
                       setIsOpen={() => setCartModal()}/>
            <div className="categories">
                {categories?.length ?
                    <div className={'double-categories'}>
                        <div
                            // spaceBetween={10}
                            // slidesPerView={2}
                            // navigation={true}
                            // autoplay={{
                            //     delay: 2500,
                            //     disableOnInteraction: false,
                            // }}
                        >
                            {categories?.length ? categories.filter((item, index) => item[`title_${i18n.language}`] != t('recommended')).map((item, index) =>
                                <div className={item.id == categoryId ? 'active category-card' : 'category-card'}
                                             key={index}
                                             onClick={(e) => getCategoryProducts(e, item)}>
                                    <div className={'category-block'}>
                                        {loading ? <Skeleton count={1} height={80}/>
                                            : <img src={item.photo} alt={item[`title_${i18n.language}`]}/>}
                                        <h5 className={'category-title'}>{item[`title_${i18n.language}`]}</h5>
                                    </div>
                                </div>
                            ) : <Skeleton count={2}/>}
                        </div>
                        {/*<Swiper*/}
                        {/*    autoplay={{*/}
                        {/*        delay: 2500,*/}
                        {/*        disableOnInteraction: false,*/}
                        {/*    }}*/}
                        {/*    spaceBetween={10}*/}
                        {/*    slidesPerView={2}*/}
                        {/*    navigation={true}*/}
                        {/*>*/}
                        {/*    {categories ? categories.filter((item, index) => index % 2 === 1*/}
                        {/*        && item[`title_${i18n.language}`] != t('recommended')).map((item, index) =>*/}
                        {/*        <div className={item.id == categoryId ? 'active category-card' : 'category-card'}*/}
                        {/*                     key={index}*/}
                        {/*                     onClick={(e) => getCategoryProducts(e, item)}>*/}
                        {/*            <div className={'category-block'}>*/}
                        {/*                {loading ? <Skeleton count={1} height={80}/>*/}
                        {/*                    : <img src={item.photo} alt={item[`title_${i18n.language}`]}/>}*/}
                        {/*                <h5 className={'category-title'}>{item[`title_${i18n.language}`]}</h5>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    ) : <Skeleton count={2}/>}*/}
                        {/*</Swiper>*/}
                    </div>
                    :
                    <div></div>
                    // <Swiper
                    //     autoplay={{
                    //         delay: 2500,
                    //         disableOnInteraction: false,
                    //     }}
                    //     spaceBetween={10}
                    //     slidesPerView={2}
                    //     navigation={true}
                    // >
                    //     {categories ? categories.filter((item) =>
                    //         item[`title_${i18n.language}`] != t('recommended')).map((item, index) =>
                    //         <SwiperSlide className={item.id == categoryId ? 'active category-card' : 'category-card'}
                    //                      key={index}
                    //                      onClick={(e) => getCategoryProducts(e, item)}>
                    //             <div className={'category-block'}>
                    //                 {loading ? <Skeleton count={1} height={80}/>
                    //                     : <img src={item.photo} alt={item[`title_${i18n.language}`]}/>}
                    //                 <h5 className={'category-title'}>{item[`title_${i18n.language}`]}</h5>
                    //             </div>
                    //         </SwiperSlide>
                    //     ) : ''}
                    // </Swiper>
                }
            </div>
            {categories ? categories.filter((item) =>
                item[`title_${i18n.language}`] == t('recommended')).map((item, index) =>
            <div className={'recommend-block'} key={index} onClick={(e) => getCategoryProducts(e, item)}>
                    <div className={item.id == categoryId ? 'active category-block' : 'category-block'}>
                        {loading ? <Skeleton count={1} height={250}/>
                            : <img src={item.photo} alt={item[`title_${i18n.language}`]}/>}
                        <h5 className={'category-title'}>{item[`title_${i18n.language}`]}</h5>
                    </div>
            </div>) : ''}
            <div className={'search-box filter-product'}>
                <form id={'filter'}>
                    <label className={'search-label'}>
                        <TextField id="input-with-icon-textfield" onFocus={windowTop} onChange={Filtering}
                                   name={'search'}
                                   className={'search-input'} label={t('search')} type="search"
                                   InputProps={{
                                       endAdornment: (
                                           <InputAdornment className={!searchWord ? '' : 'd-none'} position="end">
                                               <SearchIcon color={'#fff'}/>
                                           </InputAdornment>
                                       )
                                   }}/>
                    </label>
                </form>
            </div>
            {loadingProduct ?
                <div className={'products'}>
                    <div className={'p-2'}><Skeleton count={5} style={{'height': '250px', 'margin': '10px 20px', "width": "90%", 'borderRadius': '20px'}}/></div>
                </div>
                : !products?.length ? <div style={{'padding': "10px", "textAlign": "center"}}>{t("product_notfound")}</div> :
                <div className={'products'}>
                    <InfiniteScroll
                        dataLength={products ? products.length : 1}
                        next={getNextPage}
                        hasMore={props.product.products.next ? true : false} // Replace with a condition based on your data source
                        loader={<div className={'p-2'}><Skeleton count={5}
                                                                 style={{'height': '250px', 'margin': '10px 20px', "width": "90%", 'borderRadius': '20px'}}/></div>}
                        endMessage={''}>
                        {products ? products.map(item =>
                            <div className="product" key={item.id} onClick={() => orderProduct(item)}>
                                <div className="product-card">
                                    <div className={'product-body'}>
                                        <h5 className="title">
                                            {item[`title_${i18n.language}`]}
                                        </h5>
                                        <p className={'description'}>
                                            {item[`description_${i18n.language}`]}
                                        </p>
                                        <div className={'price-block'}>
                                            <h6>{item.price} {t('sum')}</h6>
                                            <div className="addToCart">
                                                <IconButton color="default" aria-label="add to shopping cart">
                                                    <svg id="Capa_1" enableBackground="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m490.299 185.717h-106.219l-59.584-136.433c-3.315-7.591-12.157-11.06-19.749-7.743-7.592 3.315-11.059 12.158-7.743 19.75l54.34 124.427h-190.688l54.34-124.427c3.315-7.592-.151-16.434-7.743-19.75-7.591-3.317-16.435.15-19.749 7.743l-59.584 136.433h-106.219c-13.895 0-24.207 12.579-21.167 25.82l55.935 243.63c2.221 9.674 11.015 16.55 21.167 16.55h356.728c10.152 0 18.946-6.876 21.167-16.55l55.935-243.63c3.04-13.24-7.273-25.82-21.167-25.82zm-359.557 46.004c-2.004 0-4.041-.404-5.996-1.258-7.592-3.315-11.059-12.157-7.743-19.75l11.268-25.802h32.736l-16.512 37.808c-2.461 5.639-7.971 9.002-13.753 9.002zm50.258 159.996c0 8.284-6.716 15-15 15s-15-6.716-15-15v-110c0-8.284 6.716-15 15-15s15 6.716 15 15zm90 0c0 8.284-6.716 15-15 15s-15-6.716-15-15v-110c0-8.284 6.716-15 15-15s15 6.716 15 15zm90 0c0 8.284-6.716 15-15 15s-15-6.716-15-15v-110c0-8.284 6.716-15 15-15s15 6.716 15 15zm26.253-161.254c-1.954.854-3.991 1.258-5.995 1.258-5.782 0-11.292-3.362-13.754-9.001l-16.512-37.808h32.736l11.268 25.802c3.316 7.592-.151 16.434-7.743 19.749z"/></svg>
                                                    {/*<AddShoppingCartIcon />*/}
                                                </IconButton>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'product-img'}>
                                        <img src={item.photo} alt={item[`title_${i18n.language}`]}/>
                                    </div>
                                </div>

                            </div>
                        ) : <div className={'p-2'}><Skeleton count={5} style={{'height': '250px', 'margin': '10px 20px', "width": "90%", 'borderRadius': '20px'}}/>
                        </div>}
                    </InfiniteScroll>
                </div>}
            {!cartModal ? <div className={'cart-button'} onClick={openCart}>
                <span>{t('cart')} {nextPage}</span>
                <span><b>{numberWithSpaces(allSumCart)} {t('sum')}</b></span>
            </div> : ''}

        </main>
    )
}

export default connect(props => props, {
    getAllCart, getProducts, getFilteredProducts,
    getCategories, getProductNextPage, getProductTitleFilter, getMyUser, messageReset
})(App)