import * as React from 'react';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import {connect} from "react-redux";
import {deleteCart, editMyCart, getAllCart, messageReset} from "../../store/cart";
import {getMyUser} from "../../store/User";
import {PatternFormat} from "react-number-format";
import {getAllOrders, ordersSave} from "../../store/Orders";
import {toast} from "react-toastify";
import AddCardIcon from '@mui/icons-material/AddCard';
import WalletIcon from '@mui/icons-material/Wallet';
import InfoIcon from '@mui/icons-material/Info';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import {useEffect, useState} from "react";
import CloseButton from "react-bootstrap/CloseButton";
import {useTelegram} from "../useTelegram";
import {styled, Tooltip, tooltipClasses} from "@mui/material";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import Avatar from '@mui/joy/Avatar';
import FormLabel from '@mui/joy/FormLabel';
import Radio, { radioClasses } from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
import Sheet from '@mui/joy/Sheet';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function numberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

function CartModal(props) {
    const {tg, userTg, onClose} = useTelegram()
    const {t, i18n} = useTranslation()
    const [myUser, setMyUser] = useState(false);
    const [cashback, setCashback] = useState(0);
    const [open, setOpen] = useState(false);
    const [openTooltip, setOpenTooltip] = useState(false);
    const [allSumCart, setAllSumCart] = useState(0)
    const [orderType, setOrderType] = useState(false)
    const [allSum, setAllSum] = useState(0)
    const [cart, setCart] = useState([]);
    const [hasError, setHasError] = useState(false);
    const [fullName, setFullName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [comment, setComment] = useState('');

    useEffect(() => {
        props.getAllCart(userTg ? userTg.id : '11')
        props.getMyUser(userTg ? userTg.id : '11')
    }, [])

    useEffect(() => {
        if (!cart.length) {
            handleClose()
        }
        setAllSumCart(props.allSumCart)
        if (props.orders.message){
            onClose()
        }

    }, [props])

    useEffect(() => {
        setAllSumCart(props.allSumCart)
        if (props.cart.message == 'deleted') {
            props.getAllCart(userTg ? userTg.id : '11')
        }
        if (!props.cart.cart) {
            props.setIsOpen(false)
        }
        let myModalCart = props.cart.cart
        setCart(myModalCart)
        props.setCartApp(myModalCart)
    }, [props.cart])

    useEffect(() => {
        setMyUser(props.user.user)
    }, [props.user])

    useEffect(() => {
        setAllSum(Number(allSumCart) - Number(cashback))
    }, [allSumCart])

    function getPrice(item){
        let sum_price = 0
        sum_price += item.product.price * Number(item.quantity);
        if(item.attr.length){
            for (let j = 0; j < item.attr.length; j++) {
                sum_price += item.attr[j].add_sum * Number(item.quantity)
            }
        }
        return sum_price
    }

    function changeTypeOrder(val){
        setOrderType(val)
    }

    function getAttr(item){
        let myAttr = ''
        for (let i = 0; i < item.attr.length; i++) {
            myAttr += item.attr[i][`title_${i18n.language}`].toString()
            if (i !== item.attr.length-1){
                myAttr += '\n'
            }
        }
        return myAttr
    }

    function AddQuantity(product_item) {
        let newNum = product_item['quantity']
        newNum++
        props.editMyCart({...product_item, quantity: newNum})
    }

    function RemoveQuantity(product_item) {
        let newNum = product_item['quantity']
        newNum--
        props.editMyCart({...product_item, quantity: newNum})
    }

    function changePhoneNumber(n) {
        const myNewNum = n.replace(/[^0-9\d]/ig, "")
        setPhoneNumber(myNewNum);
    }

    function handleClose() {
        props.setIsOpen(false);
        setOpen(false);
    }

    useEffect(() => {
        setAllSum(Number(allSumCart) - Number(cashback))
    }, [cashback])

    function deleteProduct(item) {
        props.deleteCart(item)
    }

    useEffect(() => {
        props.getAllCart(userTg ? userTg.id : '11')
        setOpen(props.isOpen)
    }, [props.isOpen])

    function OrderProducts(e) {
        e.preventDefault()
        if (phoneNumber.length < 9 || fullName.length < 3 || orderType === false) {
            setHasError(true)
        } else {
            setHasError(false)
            const data = {
                buyer: userTg ? userTg.id : '11',
                fullname: fullName,
                phone_number: "+998" + phoneNumber,
                comment: comment,
                money_used: cashback,
                orders: cart,
                order_types: orderType
            }
            props.ordersSave(data)
            setAllSumCart(0)
            setAllSum(0)
            setCashback(0)
            setPhoneNumber('')
            setOrderType(false)
            setFullName('')
            setComment('')
            handleClose()
        }
    }

    const handleTooltipClose = () => {
        setOpenTooltip(false);
    };

    const handleTooltipOpen = () => {
        setOpenTooltip(!openTooltip);
    };

    const HtmlTooltip = styled(({className, ...props}) => (
        <Tooltip arrow {...props} classes={{popper: className}} onClose={handleTooltipClose}
                 open={openTooltip}
                 disableFocusListener
                 disableHoverListener
                 disableTouchListener/>
    ))(({theme}) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }));

    return (
        <div>
            {props.cart && props.cart.cart ?
                <Dialog
                    fullScreen
                    open={props.isOpen ? props.isOpen : false}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                    className={'cart-modal'}
                >
                    <div className={'cart-header'}>
                        <h2 className="h2">{t('in_cart')} {cart ? cart.length : 0} {t("incart_end")}</h2>
                    </div>
                    <div className={'balance'}>
                        <h3>{t('your_balance')}</h3>
                        <div className={'card-balance'}>
                            <p>{t('cashback_title')}</p>
                            <h4>{numberWithSpaces(myUser.balance ? myUser.balance : 0)} {t('sum')}</h4>
                            <ClickAwayListener onClickAway={handleTooltipClose}>
                                <div>
                                    <HtmlTooltip
                                        title={
                                            <React.Fragment>
                                                {t('cashback_desc')}
                                            </React.Fragment>
                                        }
                                    >
                                        <Button onClick={handleTooltipOpen}><InfoIcon/></Button>
                                    </HtmlTooltip>
                                </div>
                            </ClickAwayListener>
                            <img src="https://buyukada.bekzodbek.uz/media/images/cashback1.png" alt="Cashback"/>
                        </div>
                    </div>
                    {cart.map((item, index) =>
                        <div className={'cart-product'} key={index}>
                            <div className={'cart-product-photo'}>
                                <img src={item.product.photo} alt={item.product[`title_${i18n.language}`]}/>
                            </div>
                            <div className={'cart-product-body'}>
                                <h3>{item.product[`title_${i18n.language}`]}<DeleteForeverOutlinedIcon onClick={() => deleteProduct(item)}/>
                                </h3>
                                {item.attr?.length ?
                                    <p style={{"whiteSpace": "pre-line"}} className={'attr'}>{getAttr(item)}</p>
                                    : ''}
                                <div className={'addToCartBlock quantityBlock'}>
                                    <h4 className={'price-product'}>{getPrice(item)} {t('sum')}</h4>
                                    <div className={'counter'}>
                                        <button onClick={() => RemoveQuantity(item)}
                                                disabled={item.quantity === 1 ? true : false}
                                                className={`quantityBtn`}>-
                                        </button>
                                        <span className={'quantity'}>{item.quantity}</span>
                                        <button onClick={() => AddQuantity(item)} className={'quantityBtn'}>+</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="checkout">
                        <div className="cart-form__cart-section cart-section">
                            <div className="cart-section__header">
                                <h2 className="h2">{t('your_info')}</h2></div>
                            <div className="cart-section__body">
                                <form className="similar-auth" onSubmit={OrderProducts}>
                                    <div>
                                        <label className="similar-auth__label">
                                            {t('fio')}</label>
                                        <input type="text" className="input" min={3} value={fullName}
                                               onChange={(e) => setFullName(e.target.value)}/>
                                        {fullName.length < 3 && fullName
                                            ?
                                            <span role={'alert'} className={'d-block validation-error'}>{t('fio_error1')}</span>
                                            : !fullName && hasError ?
                                                <span role={'alert'} className={'d-block validation-error'}>{t('fio_error2')}</span> : ''}
                                    </div>
                                    <div>
                                        <label className="similar-auth__label">
                                            <span className="">{t('phone')}</span>
                                        </label>
                                        <div className="phone-input">
                                            <div className="phone-input__prefix">
                                                <div className="phone-input__prefix-value">+998</div>
                                            </div>
                                            <PatternFormat
                                                type="tel"
                                                className="input _phone"
                                                format="## ### ## ##"
                                                mask=" "
                                                onValueChange={value => changePhoneNumber(value.formattedValue)}
                                            />
                                        </div>
                                        {phoneNumber.length !== 9 && phoneNumber
                                            ? <span role={'alert'} className={'d-block validation-error'}>{t('phone_error1')}</span>
                                            : !phoneNumber && hasError ?
                                                <span role={'alert'} className={'d-block validation-error'}>{t('phone_error1')}</span> : ''}
                                    </div>
                                    <div>
                                        <label className="similar-auth__label">
                                            {t('comment')}</label>
                                        <textarea className="input" value={comment}
                                                  onChange={(e) => setComment(e.target.value)}/>
                                    </div>
                                    {myUser?.balance ? <div className={'use-cashback'}>
                                        <label className="similar-auth__label">
                                            {t('cashback')}</label>
                                        <input type="number" className="input" min={0} max={allSumCart}
                                               value={cashback ? cashback : ''}
                                               onChange={(e) => setCashback(e.target.value)}/>
                                        {cashback > myUser.balance && cashback
                                            ?
                                            <span role={'alert'} className={'d-block validation-error'}>{t('cashback_less')}</span>
                                            : cashback > allSumCart ?
                                                <span role={'alert'} className={'d-block validation-error'}>
                                                    {t('cashback_less1')}
                                                </span> : ''}
                                    </div> : ''}
                                    {cashback && cashback > 0 && cashback < myUser?.balance ?
                                        <div className="checkout-price cashback-price">
                                            <h6 className="cart-form__sidebar-price-label">{t('cashback_used')}:</h6>
                                            <div className="price">
                                                <h6 className="price__main">{numberWithSpaces(cashback)}</h6>
                                            </div>
                                        </div>
                                        : ''}
                                    <div className={"order_types"}>
                                        <h2 className="h2">{t('order_types')}</h2>
                                        <RadioGroup
                                            aria-label="platform"
                                            defaultValue="Website"
                                            overlay
                                            onChange={(e)=>changeTypeOrder(e.target.value)}
                                            name="platform"
                                            sx={{
                                                margin: "0px 2px",
                                                flexDirection: 'column',
                                                gap: 1.5,
                                                [`& .${radioClasses.checked}`]: {
                                                    [`& .${radioClasses.action}`]: {
                                                        inset: -1,
                                                        border: '3px solid',
                                                        borderColor: '#BA704F',
                                                    },
                                                },
                                                [`& .${radioClasses.radio}`]: {
                                                    display: 'contents',
                                                    '& > svg': {
                                                        zIndex: 2,
                                                        position: 'absolute',
                                                        top: '-8px',
                                                        right: '-8px',
                                                        bgcolor: 'background.surface',
                                                        borderRadius: '50%',
                                                        fill: "#BA704F"
                                                    },
                                                },
                                            }}
                                        >
                                            {['type_cash', 'type_card'].map((value) => (
                                                <Sheet
                                                    key={value}
                                                    variant="outlined"
                                                    sx={{
                                                        borderRadius: 'md',
                                                        boxShadow: 'sm',
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        gap: 1.5,
                                                        p: 2,
                                                        width: "100%"
                                                    }}
                                                >
                                                    <Radio id={value} className={'radio-type'} value={value} checkedIcon={<CheckCircleRoundedIcon />} />
                                                    {value=='type_cash' ? <WalletIcon variant="soft" size="sm" />
                                                        : <AddCardIcon variant="soft" size="sm" />}
                                                    <FormLabel htmlFor={value}>{t(value)}</FormLabel>
                                                </Sheet>
                                            ))}
                                        </RadioGroup>
                                        {!orderType && hasError
                                            ?
                                            <span role={'alert'} className={'d-block validation-error'}>{t('ordertype_error')}</span> : ''}
                                    </div>
                                    <div className="checkout-price all-price">
                                        <h4 className="cart-form__sidebar-price-label">{t('all_sum')}:</h4>
                                        <div className="price">
                                            <h4 className="price__main">{numberWithSpaces(allSum)} {t('sum')}</h4>
                                        </div>
                                    </div>
                                    <button type="submit" className="btn btn__red btn__big _fw btn-order">
                                        {t('order_now')}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <CloseButton onClick={handleClose} className={'close-btn'}/>
                </Dialog>
                : ''}
        </div>
    );
}

export default connect(props => props, {
    getAllCart, deleteCart, getMyUser, getAllOrders,
    editMyCart, ordersSave, messageReset
})(CartModal)
